/*  DATATABLES */
table.dataTable {
    border-collapse: collapse !important;
}

table.dataTable.dtr-inline.collapsed > tbody > tr > td:first-child:before,
table.dataTable.dtr-inline.collapsed > tbody > tr > th:first-child:before {
    line-height: 18px !important;
}

.container-fluid.dt-bootstrap4 {
    padding: 0;
}
