@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: normal;
  src: url('./assets/fonts/Nunito-Regular.ttf');
}
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: normal;
  src: url('./assets/fonts/Nunito-Italic.ttf');
}

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: bold;
  src: url('./assets/fonts/Nunito-Bold.ttf');
}
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: normal;
  src: url('./assets/fonts/Nunito-BoldItalic.ttf');
}

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: light;
  src: url('./assets/fonts/Nunito-Light.ttf');
}
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: normal;
  src: url('./assets/fonts/Nunito-LightItalic.ttf');
}

/* You can add global styles to this file, and also import other style files */
$green: #24C064;
$blue: #12719a;
$blue-dark: #002c51;
$blue-light: #3678c8;
$blue-light-hover: darken($blue-light, 10);
$blue-lighten: #eaf3fc;
$gray: #717171;
$gray-dark: #2d2d2d;
$gray-light: #f4f4f4;
$gray-lighten: #efefef;
$gray-lighten2 : #f8fcfe;
$orange: #ef7d03;
$orange-light: #f4967c;
$red: #f30b3d;
$yellow: #fed130;
$yellow-dark: #FDCF44;
$yellow-lighten: #fff6da;
$white: #FFF;
$black: #000;
$light-blue: #e8f3fc;
$font-primary: $gray-dark;
$exceptions: $orange-light;
$pre-inscriptions: $blue-light;
$pre-inscriptions-color : $blue;
$warranty-color : $green;
$exception-color : $orange;

$font-base-size: 14px;

$space: 20px;

body {
  font-family: 'Nunito', sans-serif !important;
  font-weight: 400 !important;
  font-size: 16px;
  height: 100%;
  background: white;
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left) !important;
}

body > .alert {
  margin-top: env(safe-area-inset-top) !important;
}

.cat__menu-left {
  padding-top: env(safe-area-inset-top) !important;
}

.mat-typography, mat-toolbar.mat-toolbar { 
  font-size: 14px;
  @media (min-width: 600px) {
    font-size: 16px;
  }
}

.mat-form-field-label, .mat-typography, .mat-list-item, mat-toolbar.mat-toolbar {
  font-family: 'Nunito', sans-serif !important;
  letter-spacing: normal;   
  font-weight: 400 !important;
  color: #5f5f5f;
}
/**
Prevent zoom on mobiles ios.
 */
@media screen and (-webkit-min-device-pixel-ratio:0) {
  select,
  textarea,
  input {
    font-size: 16px;
  }
}
@media screen and (-webkit-min-device-pixel-ratio:0) {
  input[type="email"]:hover,
  input[type="number"]:hover,
  input[type="search"]:hover,
  input[type="text"]:hover,
  input[type="tel"]:hover,
  input[type="url"]:hover,
  input[type="password"]:hover,
  textarea:hover,
  select:hover{font-size: initial;}
}
@media (min-width: 768px) {
  input[type="email"]:hover,
  input[type="number"]:hover,
  input[type="search"]:hover,
  input[type="text"]:hover,
  input[type="tel"]:hover,
  input[type="url"]:hover,
  input[type="password"]:hover,
  textarea:hover,
  select:hover{font-size: inherit;}
}

button:disabled {
  opacity: unset !important;
}

a {
  cursor: pointer;
}
.cat__content.login {
  height: 100%;
  padding-top: 0rem !important;
  margin-left: 0;
}
.cat__content {
  background: white;
}
// Hides some divs to demo.
.cat__top-bar__item:first-child {
  display: none;
}

// General Styles colors.

// Headings

.cat__core__title {
  font-size: 1.65em;
  display: inline-block;
  margin: 10px 15px;
  font-weight: 400;
  .svg-icon {
    width: 26px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
  }
}

.cat__theme--blue .cat__top-bar {
  background-color: $blue-dark;
}

.cat__top-bar {
  padding-right: 0rem;
  padding-left: 0rem;
  height: calc(env(safe-area-inset-top) + 64px);
  padding-top: env(safe-area-inset-top);
}

.cat__top-bar__right {
  height: 77%;
  margin-right: 1rem;
}

body.cat__theme--blue .cat__menu-left {
  background: rgba(0,44,81,1);
  background: -moz-linear-gradient(top, rgba(0,44,81,1) 66%, rgba(0,110,164,1) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(66%, rgba(0,44,81,1)), color-stop(100%, rgba(0,110,164,1)));
  background: -webkit-linear-gradient(top, rgba(0,44,81,1) 66%, rgba(0,110,164,1) 100%);
  background: -o-linear-gradient(top, rgba(0,44,81,1) 66%, rgba(0,110,164,1) 100%);
  background: -ms-linear-gradient(top, rgba(0,44,81,1) 66%, rgba(0,110,164,1) 100%);
  background: linear-gradient(to bottom, rgba(0,44,81,1) 66%, rgba(0,110,164,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#002c51', endColorstr='#006ea4', GradientType=0 );
}

.cat__top-bar__left {
  display: block;
  text-align: center;
}

.cat__top-bar__logo {
  position: absolute;
  width: 100%;
  left: 0;
}

.cat__top-bar__avatar-dropdown > a {
  color: $white;
}
.cat__top-bar__avatar {
  vertical-align: middle;
  margin-left: 5px;
}
// Tables.
.ngx-datatable.material {
  background: $white;
  box-shadow: none;
}

.ngx-datatable.bootstrap:not(.cell-selection) .datatable-body-row:hover,
.ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group
{
  background: $gray-light;
}

.ngx-datatable .datatable-header {
  .datatable-header-inner,
  .datatable-row-center {
    //width: 100% !important;
  }
}
.ngx-datatable .datatable-body {
  .datatable-scroll,
  .datatable-row-center {
    //width: 100% !important;
  }
  .datatable-row-wrapper {
    border-bottom: 1.2px solid lighten($blue-light, 10);
  }
  .datatable-row-wrapper:nth-child(2n) {
    background: lighten($light-blue, 4);
  }
}

.ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell {
  font-size: 1.1em;
  font-weight: 400;
  color: #333;
}



// Icons

.claimtype--icon:before {
  color: white;
  border-radius: 80px;
  margin: 0;
  width: 35px;
  height: 35px;
  left: 0;
  text-align: center;
  top: 0;
  display: inline-block;
}

.circle-button {
  color: white;
  border-radius: 80px;
  width: 35px;
  height: 35px;
  line-height: 35px;
  left: 0;
  text-align: center;
  top: 0;
  display: inline-block;
  &.blue {
    background: $blue;
    color: $white;
  }
}

.circle--icon {
  border: 1px solid;
  border-radius: 50px;
  padding: 5px 10px;
  margin: 5px 0;
}

.circle--icon-white {
  border-color: $gray;
  color: $gray;
}

.claimtype--icon1:before {
  content: 'P';
  background: $pre-inscriptions-color;
}

.claimtype--icon2:before {
  content: 'G';
  background: $warranty-color ;

}

.claimtype--icon3:before {
  content: 'E';
  background: $exception-color;

}

.claimtype--icon4:before {
  content: 'V';
  background: #c22d67;

}


/*
body.filter-right--visible cat-page section.card  {
width: calc(100% - 330px);
transition: -webkit-transform .2s ease-in-out;
transition: transform .2s ease-in-out;
}*/

.cat__menu-right-filter {
  top: 64px;
  bottom: 0;
  height: auto;
  background: #12729a;
  color: white;
  .form-group {
    margin-bottom: 0rem;
  }
}

body.filter-right--visible .cat__menu-right-filter {
  box-shadow: none;
}

b, strong {
  font-weight: 500;
  color: #333;
}

.cat__menu-right__label {
  color: #fff;
  display: none;
}

.cat__menu-right__header {
  color: #fff;
}

.cat__menu-right__content {
  padding: 1.42rem 0.5rem;
}

.select2 .select2-selection--multiple {
  opacity: 1;
}


.btn.btn-danger, .show > .btn.btn-danger {
  background-color: #ef7d03;
  border-color: #ef7d03;
}

.btn.btn-success, .show > .btn.btn-success {
  background-color: #24C064;
  border-color: #24C064;
}

.btn.btn-primary, .show > .btn.btn-primary {
  background-color: $blue-light;
  border-color: $blue-light;
}
.btn.btn-primary:hover, .btn.btn-primary:hover:active, .btn.btn-primary:focus, .btn.btn-primary.active, .show > .btn.btn-primary:hover:active, .show > .btn.btn-primary:focus, .show > .btn.btn-primary.active {
  background: $blue-light-hover !important;
  background-color: $blue-light-hover !important;
  border-color: $blue-light-hover !important;
}

.btn.btn-outline-primary.btn-icon {
  background-color: $blue-light;
  border-color: $blue-light;
  color: $blue-light;
}

.cat__core__scroll-top {
  display: none;
}

.card-block .filter-block {
  margin: 0 auto 20px;
  width: 80%;
  .form-input-icon {
    position: relative;
    max-width: 400px;
    margin: 0 auto;
  }
  input {
    margin-bottom: 20px;
  }
}

.cat__footer {
  margin-bottom: 0;
  background: $gray-lighten2;
  border-radius: 0;
  padding: 10px 20px;
  img {
    display: none;
  }
}

.cat__footer__bottom {
  margin-top: 0;
  padding-top: 0;
  border-top: 0;
}

.cat__breakline__top {
  margin-top: 0px;
}

p span
{
  display: block ;
  .desktop-hidden-1200 {
    display: block
  }
  .desktop-hidden-1000 {
    display: block
  }
  .desktop-hidden-900 {
    display: block
  }
  .desktop-hidden-800 {
    display: block
  }
  .desktop-hidden-700 {
    display: block
  }
  .desktop-hidden-600 {
    display: block
  }
  .desktop-hidden-500 {
    display: block
  }
  .desktop-hidden-400 {
    display: block
  }
}

// Styles for Advanced Buttons in claims.
.table--icons-tools {
  float: right;

  button:focus {
    outline: 0 !important;
  }

  .svg-icon {
    display: inline-block;
    width: 30px;
    height: 30px;
    padding: 3px;
    margin: 10px 2px;
    border: 1px solid $gray-light;
    fill: $gray-light;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    &:hover {
      border: 1px solid #89af3f;
      fill: #89af3f;
    }



    &:active {
      border: 1px solid #89af3f;
      fill: #89af3f;
    }
  }

}

.svg-icon svg {
  width: 100%;
  height: 100%;
}

// Checkbox styles.
.checkbox {
  padding-left: 20px; }
.checkbox label {
  display: inline-block;
  position: relative;
  padding-left: 5px; }
.checkbox label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 17px;
  height: 17px;
  left: 0;
  margin-left: -20px;
  border: 1px solid #fb434a;
  border-radius: 3px;
  border-width: 2px;
  background-color: #fff;
  -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out; }
.checkbox label::after {
  display: inline-block;
  position: absolute;
  width: 16px;
  height: 16px;
  left: 0;
  top: 0;
  margin-left: -20px;
  padding-left: 3px;
  padding-top: 1px;
  font-size: 11px;
  color: #555555; }
.checkbox input[type="checkbox"] {
  opacity: 0; }
.checkbox input[type="checkbox"]:focus + label::before {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px; }
.checkbox input[type="checkbox"]:checked + label::after {
  font-family: 'FontAwesome';
  content: "\f00c"; }
.checkbox input[type="checkbox"]:disabled + label {
  opacity: 0.65; }
.checkbox input[type="checkbox"]:disabled + label::before {
  background-color: #eeeeee;
  cursor: not-allowed; }
.checkbox.checkbox-circle label::before {
  border-radius: 50%; }
.checkbox.checkbox-inline {
  margin-top: 0; }

.checkbox-primary input[type="checkbox"]:checked + label::before {
  background-color: #428bca;
  border-color: #428bca; }
.checkbox-primary input[type="checkbox"]:checked + label::after {
  color: #fff; }

.checkbox-danger input[type="checkbox"]:checked + label::before {
  background-color: #d9534f;
  border-color: #d9534f; }
.checkbox-danger input[type="checkbox"]:checked + label::after {
  color: #fff; }

.checkbox-info input[type="checkbox"]:checked + label::before {
  background-color: #5bc0de;
  border-color: #5bc0de; }
.checkbox-info input[type="checkbox"]:checked + label::after {
  color: #fff; }

.checkbox-warning input[type="checkbox"]:checked + label::before {
  background-color: #f0ad4e;
  border-color: #f0ad4e; }
.checkbox-warning input[type="checkbox"]:checked + label::after {
  color: #fff; }

.checkbox-success input[type="checkbox"]:checked + label::before {
  background-color: #24C064;
  border-color: #24C064; }
.checkbox-success input[type="checkbox"]:checked + label::after {
  color: #fff; }

/* Modal Styles */
.resize-modal{ overflow-y: auto;}
.modal-header  {
  background: $blue-light;
  color: $white;
  .close {
    opacity: 1;
    color: $white;
  }
}
.modal-body {
  padding: 30px;
}

/* Responsive ngx-datatable */
.hidden {
  display: none !important;
}
@media screen and (max-width: 1200px) {
  .desktop-hidden-1200 {
    display: initial;
  }
  .mobile-hidden-1200 {
    display: none !important;
  }
}
@media screen and (min-width: 1200px) {
  .desktop-hidden-1200 {
    display: none !important;
  }
  .mobile-hidden-1200 {
    display: initial;
  }
}
@media screen and (max-width: 1000px) {
  .desktop-hidden-1000 {
    display: initial;
  }
  .mobile-hidden-1000 {
    display: none !important;
  }
}
@media screen and (min-width: 1000px) {
  .desktop-hidden-1000 {
    display: none !important;
  }
  .mobile-hidden-1000 {
    display: initial;
  }
}
@media screen and (max-width: 900px) {
  .desktop-hidden-900 {
    display: initial;
  }
  .mobile-hidden-900 {
    display: none !important;
  }
}
@media screen and (min-width: 900px) {
  .desktop-hidden-900 {
    display: none !important;
  }
  .mobile-hidden-900 {
    display: initial;
  }
}
@media screen and (max-width: 800px) {
  .desktop-hidden-800 {
    display: initial;
  }
  .mobile-hidden-800 {
    display: none !important;
  }
}
@media screen and (min-width: 800px) {
  .desktop-hidden-800 {
    display: none !important;
  }
  .mobile-hidden-800 {
    display: initial;
  }
}
@media screen and (max-width: 700px) {
  .desktop-hidden-700 {
    display: initial;
  }
  .mobile-hidden-700 {
    display: none !important;
  }
}
@media screen and (min-width: 700px) {
  .desktop-hidden-700 {
    display: none !important;
  }
  .mobile-hidden-700 {
    display: initial;
  }
}
@media screen and (max-width: 600px) {
  .desktop-hidden-600 {
    display: initial;
  }
  .mobile-hidden-600 {
    display: none !important;
  }
}
@media screen and (min-width: 600px) {
  .desktop-hidden-600 {
    display: none !important;
  }
  .mobile-hidden-600 {
    display: initial;
  }
}
@media screen and (max-width: 500px) {
  .desktop-hidden-500 {
    display: initial;
  }
  .mobile-hidden-500 {
    display: none !important;
  }
}
@media screen and (min-width: 500px) {
  .desktop-hidden-500 {
    display: none !important;
  }
  .mobile-hidden-500 {
    display: initial;
  }
}
@media screen and (max-width: 400px) {
  .desktop-hidden-400 {
    display: initial;
  }
  .mobile-hidden-400 {
    display: none !important;
  }
}
@media screen and (min-width: 400px) {
  .desktop-hidden-400 {
    display: none !important;
  }
  .mobile-hidden-400 {
    display: initial;
  }
}

/* Responsive ngx-datatable */
.icon {
  width: 15px;
}

.hidden-close-cell {
  width: 0px !important
}

/* Styles from Claim */
.btn .btn-rounded-problem-detail {
  width: 25% !important
}

/* Styles from icons and labels */
.icon-padding-right {
  padding-right: 7px;
}

/* Text align  */
.right{text-align:right;}
.left{text-align:left;}
.text-center {
  text-align: center;
}

.my-drop-zone {
  border: dotted 3px lightgray;
  cursor: pointer;
  padding: 10px 9px 5px;
  text-align: center;
  margin: 5px 0 0 0;
  &.hide-dots {
    border: 0;
  }
  &.nv-file-over {
    border: dotted 3px $orange;
  }
  label {
    display: inline-block;
    vertical-align: top;
    padding-top: 5px;
    margin-right: 3px;
  }
}

.nv-file-over { border: dotted 3px red; } /* Default class applied to drop zones on over */
.another-file-over-class { border: dotted 3px green; }

/* show images upload */
.images-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.image-holder {
  padding: 2px; /*for white border around images*/
  height: 120px;
}

.image-holder-min {
  padding: 2px; /*for white border around images*/
  height: 40px;
}

/* .image-holder img {
height: 100%;
} */

/* Close button image */
.img-wrap {
  position: relative;
  font-size: 0;
}
.img-wrap .close {
  position: absolute;
  top: 2px;
  right: 2px;
  z-index: 100;
  background-color: #FFF;
  padding: 5px 2px 2px;
  color: #000;
  font-weight: bold;
  cursor: pointer;
  opacity: .2;
  text-align: center;
  font-size: 22px;
  line-height: 10px;
  border-radius: 50%;
}
.img-wrap:hover .close {
  opacity: 1;
}

/* disabled widnow while is loading */
.overlay-progress.disabledwindow {
  position: fixed;
  pointer-events: none;
  opacity: 0.5;
  top: 2px;
  left: 0;
  background: rgba(113, 160, 215, 0.82);  
  width: 100vw;
  z-index: 99999;
  height: 100vh;
  #loading-bar-spinner.spinner {
    left: 50%;
    margin-left: -50px;
    top: 50%;
    margin-top: -50px;
    position: absolute;
    z-index: 19 !important;
    animation: loading-bar-spinner 400ms linear infinite;
  }
  
  #loading-bar-spinner.spinner .spinner-icon {
    width: 100px;
    height: 100px;
    border:  solid 4px transparent;
    border-top-color:  #bebebe !important;
    border-left-color: #bebebe  !important;
    border-radius: 50%;
  }
  
  @keyframes loading-bar-spinner {
  0%   { transform: rotate(0deg);   transform: rotate(0deg); }
  100% { transform: rotate(360deg); transform: rotate(360deg); }
  }
}

.overlyay-progress {
  display: none;
}

/* active step button */
.background-step-active {
  background-color: green;
}
.displayBlock {
  display: block;
}
.imgCompany {
  width: 70% !important;
}
.imgLogo {
  width: 50% !important;
}
.line {
  margin: 0;
  padding: 0;
  background-color: $gray;
  height: 1px !important;
}
.line2 {
  margin: 0;
  background-color: #ddebf6;
  height: 4px;
  border: antiquewhite;
}
.backGroundGray {
  margin: 0 !important;
  padding:10px;
  background-color: $gray-lighten;
}

.backGroundGreen {
  padding: 2px 12px;
  font-size: 9px;
  background-color: darkseagreen;
  margin: 5px;
}

.blockcenter {
  margin: auto;
  border-radius: 30px;
  width: 20rem;
  font-weight: bold;
}

.buttonlogin {
  margin: 0.5rem;
}

.bottom-login {
  color: #909090;
  a {
    color: #909090;
  }
}

.padding-login {
  max-width: 400px;
  margin: 0 auto;
  left: 0;
  input {
    background-color: white !important;
  }
  button {
    border-radius: 30px;
    font-weight: bold;
  }
}

.form-horizontal {
  padding-top: 5%;
}

.btn-secondary {
  color: #909090 !important;
  background-color: #b8beca !important;
  border-color: #b8beca !important;
}

.colorTitle {
  color: dodgerblue;
}
.colorRed {
  color: red;
}
.marginHeader {
  margin: 40px 10px 20px 10px;
}
.fontSmall {
  font-size: smaller;
}

/* Styles from Forms */
.border-class {
  border:thin black solid;
  margin:5px;
  padding:5px;
}

.requiredEm {float:right; color:#E05C65; padding-left: 10px;}
.requiredForm {float:right; color:#E05C65; padding-left: 10px;}


.cat__menu-left__submenu > .cat__menu-left__list {
  padding-left: 20px;
}

/* Styles for Reports*/
.container {
  font-size: 60px;
  border-style: solid;
  border-color: coral;
  border-radius: 5px;
  height: 260px;
  margin: auto;
}

.content {
  text-align: center;
  position: relative;
  float: left;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Claim page styles */
.claim--activity-link {
  padding: $space/4 $space;
  border-bottom: 1px solid $gray-lighten;
}

.claim--activity-link:hover,
.claim--activity-link.selected  {
  background: $blue;
  color: $white;
}

.claim--activities h4 {
  display: block;
  clear: both;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 20px;
  border-bottom: 1px solid $gray-lighten;
  padding-bottom: 5px;
}

.claim--problems-list {
  padding-top: 0px;
}

.claim--details .col-form-label {
  padding: 3px;
}

.claim-header {
  border-bottom: 1px solid $gray-light;
  padding-bottom: $space / 2;
  margin-bottom: $space;
}

.claim-activity-title {
  background: $gray-lighten;
  padding: $space/2;
  margin-bottom: $space;
  text-transform: uppercase;
  p {
    margin-bottom: 0;
  }
}

.orange-button {
  background: $orange;
  border: $orange;
  color: $white;
  font-weight: 100;
  padding: 7px $space * 2;
  font-size: 13px;
}

.svg-button svg#Capa_1 {
  fill: white;
  width: 15px;
  display: inline-block;
  font-weight: 900;
  vertical-align: middle;
}

.problem-detail {
  font-weight: 600;
  color: #89af3f;
  font-size: 13px;
  padding: 2px 0px;
  line-height: 1.2em;
  cursor: pointer;
  margin: 0 3px 0;
}

.problem-detail.completed {
  background: #89af3f;
  color: white;
  font-weight: 400;
}


@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

.card-header.no-border {
  border-bottom: 0;
}

// Claim Page.
.claim--page {
  .cat__breakline__top {
    padding-top: 0;
  }
  .card-header {
    border-bottom: 0;
  }
  .padding-vertical {
    padding: 30px 0;
  }
  .claim--header-1 .col-md-6 {
    margin: 10px 0;
  }

  .claim-title {
    font-weight: 400;
    font-size: 17px;
  }

  .card {
    padding: $space;
  }

  .nav-tabs a.nav-link {
    padding: 10px 30px 6px 25px;
    margin: 0 2px;
    width: 100%;
    color: #7f868b;
  }

  .nav-tabs a.nav-link.active {
    font-weight: 600;
  }

  .tab-content>.active {
    -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 1s; /* Firefox < 16 */
    -ms-animation: fadein 1s; /* Internet Explorer */
    -o-animation: fadein 1s; /* Opera < 12.1 */
    animation: fadein 1s;
  }
  .nav-tabs .nav-item {
    position: relative;
    width: 100%;
    max-width: 150px;
    z-index: 1;
    text-align: center;
  }
  .nav-close {
    position: absolute;
    display: none;
    top: 10%;
    right: 3px;
    padding: 5px;
    font-size: 10px;
    color: grey;
  }
  .nav-item.active .nav-close,
  .nav-item:hover .nav-close

  {
    display: block;
  }

  &.nav-tabs-horizontal .nav-tabs .nav-item .nav-link {
    background: white;
    border: 1px solid #e6e8ea;
    border-bottom-color: $white !important;
  }

  &.nav-tabs-horizontal .nav-tabs .nav-item .nav-link.active {
    border-top: 3px solid  $blue-light !important;
  }
}

// Comments Block

.reply-block {
  margin: 10px auto;
  padding: 15px 30px;
  border-radius: 5px;
}

.reply-public {
  background: #fbfff2;
  border: 1px solid #e0f5b8;
}

.reply-private {
  background: #fff6ec;
  border: 1px solid #fddfbd;
}

.reply-initial {
  background: #ebf9ff;
  border: 1px solid #c7dfea;
  width: 100%;
}

.status-icon {
  svg {
    width: 18px;
    vertical-align: -5px;
  }

}

.svg-icon {
  &.red path {
    fill: $red !important;
  }
  &.orange path {
    fill: $orange !important;
  }
  &.blue path {
    fill: $blue-light !important;
  }
  &.green path {
    fill: $green !important;
  }
  &.printicon path {
    fill: $black !important;
    &.st2 {
      fill: $blue !important;
    }
  }
  &.lettericon path {
    fill: $black !important;
  }
  &.operationsicon path {
    fill: #30BCB1 !important;
  }
  &.client-icon path {
    fill: #46be8a !important;
  }
}

.filter-icon svg {
  width: 20px;
  vertical-align: -4px;
  margin-right: 5px;
}

.advanced-filter-button {
  background: $light-blue;
  padding: 10px 40px;
  border: 0;
  display: inline-block;
  color: #2d2d2d;
}

.card-block .form-control.claim-search {
  margin-bottom: 0;
}

.charts-header.filter-open {
  background: #f9f9f9;
  position: absolute;
  z-index: 1;
  top: 0px;
  left: 0px;
  width: 100%;
}

.charts-body {
  position: relative;

  .card {
    height: calc(100% - 1.35rem);
  }
  .avg-days {
    font-weight: 600;
    font-size: 72px;
    margin: 0;
  }
  .avg-label {
    font-size: 22px;
    font-weight: 500;
  }
  .container {
    border: 0;
  }
}
.charts-header {
  background-color: $gray-lighten;
  padding: 10px;
  .svg-icon {
    cursor: pointer;
    font-size: 13px;
  }
  h5 {
    float: left;
    width: 75%;
  }
  svg {
    margin-left: 10px;
    width: 13px;
  }
}

.input-group {
  height: 4rem;
  .input-group-addon {
    padding-top: 0rem;
    margin-left: -1rem;
    color: $gray;
    display: inline-block;
    line-height: 1.8;
    background: white;
    border: 0;
    font-weight: 500;
    padding: 0;
    i {
      display: inline-block;
      margin-right: 5px;
    }
  }
  .form-control {
    border: 0;
  }
  .form-label {
    padding: .5rem .75rem;
    color: #74708d;
  }
}

.form-control:disabled, .form-control[readonly] {
  background-color: #fff;
}

.btn.print-documents-button {
  margin-right: 5px;
}

.prints-author {
  color: $gray-light;
}

.claim--problem-details {
  svg {
    path {
      fill:$gray !important;
    }
  }
}

.cat__menu-left {
  background: white;
  svg {
    path {
      fill:$black !important;
    }
  }
}

.my-drop-zone {
  .svg-icon{
  }
  svg {
    width: 30px;
    path, circle {
      fill:$gray-light !important;
    }
  }
}

.padding-top {
  padding-top: $space / 2;
}

.padding-block-top {
  padding-top: $space * 2;
}

.padding-left-3 {
  padding-left: 3 * $space;
}

.btn {
  padding: .57rem 2.24rem;
  border-radius: 30px;
}
.btn.btn-default {
  border-radius: 30px;
}

.btn.btn-success, .show > .btn.btn-success {
  background-color: $green;
  border-color: $green;
}

btn.btn-outline-success:active, .btn.btn-outline-success:hover, .show>.btn.btn-outline-success:active, .show>.btn.btn-outline-success:hover {
  background-color: $green;
  border-color: $green;
  color: #fff;
}

.btn.btn-success:hover, .btn.btn-success:active, .show > .btn.btn-success:hover, .show > .btn.btn-success:active {
  background-color: $green;
  border-color: $green;
}

.btn.btn-success:hover:active, .btn.btn-success:focus, .btn.btn-success.active, .show > .btn.btn-success:hover:active, .show > .btn.btn-success:focus, .show > .btn.btn-success.active {
  background: $green;
  border-color: $green;
}

.btn.btn-primary:hover, .btn.btn-primary:active, .show > .btn.btn-primary:hover, .show > .btn.btn-primary:active {
  background-color: $blue;
  border-color: $blue;
}

.claim-register-list {
  margin: 0 auto;
  font-size: 14px;

  & > ul > li > div {
    background: $white;
    border-bottom: 1px solid $gray-lighten;
    &.active {
      background: $blue-light;
      color: $white;
    }
  }
  .form-buttons  {
    margin-top: $space * 2;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  li {
    background: $gray-lighten;
    padding: 0;
  }

  li.gray-lighten {
    background: lighten($gray-lighten, 4);
  }

  li.white, .white {
    background: $white;
  }
  li > div {
    padding-left: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  li li > div {
    padding-left: 60px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: 600;
  }
  li li li > div {
    padding-left: 90px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: 400;

  }
}
.expiration-wizard {
  font-size: 14px;
  .svg-icon {
    display: inline-block;
    vertical-align: middle;
    margin-right: $space / 2;
  }
  svg {
    width: 18px;
    path {
      fill: $gray !important;
    }
  }
}
.claim-wizard  .card-block{
  font-size: 14px;
  .svg-icon {
    display: inline-block;
    vertical-align: middle;
    margin-right: $space / 2;
  }
  svg {
    width: 25px;
    path {
      fill: $gray-lighten !important;
    }

  }
  .claim-register-step-1 {
    svg {
      width: 18px;
    }
    path {
      fill: $gray !important;
    }
  }
}

.cat__core__title {
  svg {
    width: 25px;
  }
}

i.icon-number {
  font-style: normal;
  margin-right: 5px;
  font-weight: 600;
}

.problem-register-list {
  text-align: left;
  padding: 10px 0;
  border-bottom: 1px solid #e8e8e8;
  .images-wrapper {
    margin-left: 25px;
  }
}
.images-wrapper {
  margin-top: 5px;
}

.claim-register-list .claim-register-list-item {
  background: white;
  padding-bottom: 20px;
}

.swal-button {
  background-color: $blue-light;
  border-radius: 25px;
  padding-left: 40px;
  padding-right: 40px;
}

.swal-icon--success__ring {
  border: 4px solid $green;
}

.swal-button--cancel {
  color: #fff;
  background-color: $red;
}

.swal-title {
  font-weight: 500;
  font-size: 20px;
}

.swal-icon--warning {
  border-color: $red;
}

.btn.btn-outline-success, .show > .btn.btn-outline-success {
  border-color: $green;
  color: $green;
}

.btn.btn-outline-success:hover, .btn.btn-outline-success:active, .show > .btn.btn-outline-success:hover, .show > .btn.btn-outline-success:active {
  background-color: $green;
  border-color: $green;
  color: $white;
}

.operations-icon svg {
  width: 30px;
}

.client-icon-size svg {
  width: 2.5rem;
}

.login-form {
  height: 100%;
  padding: 50px;
  @media only screen and (min-width : 600px) {
    width: 80%;
    margin: 0 auto;
  }

}

.login-form img {
  width: 100%;
  max-width: 233px;
  margin-bottom: 40px;
}

input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill {
  background-color: $white !important;
  background-image: none !important;
  color: rgb(0, 0, 0) !important;
}


table.charts-table {
  width: 100%;
  thead th {
    border-bottom: 1px solid $gray-light;
  }
  border: 0;
  th, td, tr {
    background: $white;
    border: 0;
    padding: 10px 0;
  }
  tr:nth-child(odd) td {
    background: lighten($light-blue, 4);
  }
  tbody th {
    font-weight: 600;
  }
  tr {
  }
}

.bootstrap-datetimepicker-widget {
  th, td {
    text-align: center;
  }
  th {
    color: #666;
  }
}

.btn-group input {
  position: absolute;
  top: -100000px;
}

.form-control.display-inline-block {
  width: 45%;
  display: inline-block;
}


.problem-detail-wrapper {
  padding: 8px 0 8px;
}

.problem-detail-wrapper.selected {
  border-right: 3px solid #a7a5a5 !important;
}

.col-problem-detail {
  border-right: 1px solid #efefef;
  padding-right: 0;
}

.problem-detail-wrapper:hover {
  border-right: 3px solid #efefef;
}

section, table {
  page-break-after:  auto;
  page-break-inside: avoid;
  page-break-before: auto;
}

@for $i from 1 through 5 {
  $margin-top: $space * $i;
  .margin-top-#{$i} {
    margin-top: $margin-top;
  }
}

.checkboxform {
  margin: 10px;
  width: 15px;
  height: 15px;
}

.ngx-datatable.material.single-selection .datatable-body-row.active .datatable-row-group {
  background: #89af3f !important;
}

.input-group {
  .select2 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
    padding: 0.78rem 0;
  }
}

//menu
.cat__menu-left__icon {
  font-weight: 100;
  font-size: 1.3em;
}
.cat__menu-left__item > a {
  color: #909090;
}
div > ul > li.cat__menu-left__item {
  padding-bottom: 10px;
  background: #fff !important;
  //text
  a {
    background: white !important;
    &:hover {
      background: white;
    }
  }
}
//sub-menu
ul > li > ul > li.cat__menu-left__item > a {
  padding: 15px 20px 0 30px;
  font-size: 18px;
  color: #0889e8!important;
  background: #fff!important;
  padding: 8px 20px 8px 30px;
  height: auto;
  min-height: 0;
  &:hover  {
    background: #f4f4f4 !important;
  }
}

//ngx - datatable
#tablereceipt > div {
  datatable-header {
    border-bottom: 1px solid white;
  }
  datatable-footer {
    border-top: 1px solid white;
  }
}

.receipt-datatable .ngx-datatable .datatable-body-cell,
.ngx-datatable .datatable-header-cell,
.ngx-datatable .datatable-body-cell {
  line-height: normal;
  @media only screen and (min-width : 600px) {
    line-height: 1.5em;
  }
}

.receipt-datatable  ngx-datatable .datatable-header .datatable-header-cell {
  display: flex;
}

.custom-cell-receipt {
  color: #2f3b7b;
  font-size: 18px;
  font-weight: 900;
}
.custom-cell-mount {
  color: #3276ca !important;
  font-size: 15px !important;
  font-weight: bold !important;
  text-align: right !important;
}

.align-column-right {
  text-align: right !important;
}

.custom-cell-icons {
  display: flex !important;
  align-items: center !important;
  justify-content: center;
  .btn-icon.btn {
    padding: 0;
  }
}

//input rounded corners
.input-rounded {
  border-radius: 25px !important;
  border: 1px solid #e4e9f0;
  border-bottom: 1px solid #e4e9f0;
}

.input-rounded,.close-icon {
  position: relative;
  padding: 10px;
}

.input-rounded:not(:valid) ~ .close-icon {
  display: none;
}

.close-icon {
  border:1px solid transparent;
  background-color: transparent;
  display: inline-block;
  vertical-align: middle;
  outline: 0;
  cursor: pointer;
}

.close-icon:after {
  content: "X";
  display: block;
  width: 15px;
  height: 15px;
  position: absolute;
  background-color: #FA9595;
  z-index:1;
  right: 35px;
  top: 0;
  bottom: 0;
  margin: auto;
  padding: 2px;
  border-radius: 50%;
  text-align: center;
  color: white;
  font-weight: normal;
  font-size: 12px;
  box-shadow: 0 0 2px #E50F0F;
  cursor: pointer;
}

//left logo
.img-logo {
  display: block;
  max-width: 8rem !important;
  max-height: 8rem !important;
  width: auto;
  height: auto;
  margin: auto;
}
.cat__menu-left__lock {
  background: white;
  color: black;
  top: env(safe-area-inset-top);
}

.cat__menu-left__pin-button {
  margin-top: 20px;
}

.cat__menu-left__logo {
  margin: -1px;
  height: 10rem;
  padding-top: 2rem;
  background: white
}

.cat__menu-left__inner {
  position: static;
}

// Superior bar
#main {
  height: 10px;
  font-size: 20px;
  font-weight: bold;
  color: white;
}

.bar-sup {
  width: 100%;
  background: rgb(15,194,95);
  background: -moz-linear-gradient(90deg, rgba(15,194,95,1) 0%, rgba(50,118,202,1) 33%, rgba(51,66,144,1) 66%, rgba(73,40,153,1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(15,194,95,1) 0%, rgba(50,118,202,1) 33%, rgba(51,66,144,1) 66%, rgba(73,40,153,1) 100%);
  background: linear-gradient(90deg, rgba(15,194,95,1) 0%, rgba(50,118,202,1) 33%, rgba(51,66,144,1) 66%, rgba(73,40,153,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0fc25f",endColorstr="#492899",GradientType=1);
  height: 10px;
}

//Input styles
input {
  border: none;
  border-bottom: solid 1px #3276ca;
  border-radius: 0rem;
}
input:focus, input:valid {
  box-shadow: none;
  outline: none;
  background-position: 0 0;
}

.close:focus, .close:hover {
  outline: 0;
}

// input:focus::-webkit-input-placeholder, input:valid::-webkit-input-placeholder {
//   color: #1abc9c;
//   font-size: 12px;
//   -webkit-transform: translateY(-1px);
//   transform: translateY(-1px);
//   visibility: visible !important;
// }

//Select2 styles override
.select2-container--default .select2-selection--single {
  border: none;
  border-bottom: solid 1px #0190fe !important;
  border-radius: 0px;
  height: 3rem;
  padding: 0.2rem;
  font-size: 18px;
  .select2-selection__arrow b {
    border-top-color: #46be8a !important;
    border-width: 7px 7px 0 7px !important;
  }
}

//button circle
.button-circle {
  border-radius: 20px !important;
  background-color: #46be8a !important;
  border-color: #46be8a !important;
  border-radius: 25px !important;
  height: 3.5rem;
}

.login-form label {
  color: #909090;
}
body.cat__theme--light .cat__menu-left__item--active>a {
  color: #909090;
}

.datatable-header--template-wrap {
  text-transform: uppercase;
  color: #909090;
}

.form-receipt #amount {
  padding-top: 0;
  padding-bottom: 0;
}

body.cat__theme--light .cat__menu-left__item>a {
  padding-top: 8px;
  padding-bottom: 8px;
}


.btn.btn-primary {
  border-radius: 30px;
}

@media (min-width: 768px) {
  body.cat__menu-left--visible .cat__content.login {
    margin-left: 0;
  }
}

.bs-popover-left {
  width: 200px;
  top: 2px;
  left: 1187px;
  text-align: center;
}

// Input type text
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance:textfield;
}

.cat__menu-left__item--active > a,
.cat__menu-left__item:hover > a, 
.cat__menu-left__item:active > a, 
.cat__menu-left__item:focus > a {
  color: #909090 !important;
}

.modal-dialog.resize-medium {
  max-width: 500px !important;
}

.modal-dialog.resize-full {
  max-width: 100% !important;
}

.full-width {
  width: 100%;
}

mat-form-field input {
  font-size: 18px !important;
}
.modal-header .modal-title {
  margin: 0;
}

section.card {
  border: 0;
}

.cat__menu-left {
  border-right: 1px solid #e9e8e8;
}
.ngx-datatable {
  .datatable-body .datatable-body-cell,
  .datatable-header-cell {
     align-items: center;
     display: flex;
  }
}

.flex {
  display: flex !important;
}

@media screen and (max-width: 800px) {
  .mobile-hidden {
    display: none !important;
  }

  .ngx-datatable .datatable-body {
    overflow-x: hidden !important;
    overflow-y: hidden;
  }
}
@media screen and (min-width: 800px) {
  .desktop-hidden {
    display: none !important;
  }
}

.cat__menu-left__submenu--toggled.cat__menu-left__item > a:after {
  display: none;
}

// Modal Styles

body.modal-open, .modal-open .modal{
  height: 100vh;
  overflow-y: hidden;
}

.modal-header .close {
  opacity: 1;
  color: #24C064;
  font-size: 2rem;
  font-weight: 400;
}

.modal-header .modal-title {
  margin: 0;
  text-align: center;
  display: block;
  width: 100%;
  color: #3678C8;
  text-transform: uppercase;
  font-weight: 500;
}

.modal-header {
  background: transparent;
  border: 0;
  padding: 0;
}
.modal {
  background: rgba(113, 160, 215, 0.82);  
}

.modal .modal-dialog {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  height: 100%;
  padding: 0;
  margin: 0 auto;
}

.modal .modal-content {
  border: none;
  border-radius: 15px;
  background: #fbfdfe;
  height: 90vh;
  width: 600px;
  max-width: 90vw;
  margin: 0 auto;
  padding: 24px;
}

.resize-full .modal-content {
  width: auto !important;
  max-width: 90vw !important;
}

.cat__core__title {
  margin: 10px 15px 10px 0;
}

.modal-footer {
  display: block;
}

.btn {
  font-weight: 400 !important;
  font-size: 1em;
  padding: 0.77rem 2.24rem
}

.pointer {
  cursor: pointer;
}

.cat__menu-left {
  overflow-y: auto;
}

.mat-select {
  background: transparent;
  padding: 10px 0;
  font-size: 1em;
  border: 0;
}
.mat-select-value {
  color: #666;
}

.mat-form-field .mat-input-element {
  color: #000;
  font-weight: 300;
}
.mat-form-field-label {
  color: #909090 !important;
  text-transform: uppercase;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #909090 !important;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-ripple, 
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #909090 !important; 
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: $black;
}
.menu-toggle {
  margin: 20px 0 0 10px;
}

body > .alert {
  width: 90vw;
}

.ngx-datatable .datatable-footer .datatable-pager .pager li {
  margin: 0 2px !important;
  a {
    padding: 3px 8px !important;
  }
  &.active a {
    cursor: text;
  }
}
.pager li > a:hover,
.pager li > a:focus,
.pager li.active > a {
  background: #e8e9e9;
  color: #000000;
  border-color: #acb7bf;
}

.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: none !important;
  background-color: #649FE6 !important;
}

.ng-star-inserted > section {
  padding: 20px 0px;
}

.cat__top-bar .dropdown-menu {
  font-size: 1.3em;
}

.mat-button-toggle-button:focus {
  outline: none;
}

@media screen and (max-width: 800px) {
  .mobile-width-80 {
    width: 100% !important;
    display: flex;
    .datatable-body-cell-label {
      width: 100%;
    }
  } 
  .mobile-fix-right {
    width: 17% !important;
    text-align: center;
    .datatable-body-cell-label {
      margin: 0 auto !important;
    }
  }
}
.back-overlay {
  display: none;
}
.popContent-shadow,
.popover-shown .back-overlay {
  display: block;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(113, 160, 215, 0.82);
  top: 0;
  left: 0;
  z-index: 1000;
}

.popover {
  border: 0;
  background: transparent;
  z-index: 1001;
  left: calc(100% - 295px) !important;
  .operations-icon svg path {
    fill: rgb(44, 99, 161);
  }
  .operations-button {
    margin: 0 5px;
  }
}

.popover-content {
  padding: 0px 14px;
  position: relative;
  z-index: 9999;
  background: white;
  border-radius: 15px;
  margin-top: 3px;
}

.cdk-overlay-pane {
  max-width: 90vw !important;
}

.receipt-amount, .receipt-expand {
  color: $blue-light;
  font-weight: 600;
}

.receipt-expand {
  display: inline-block;
  width: 40px;
}

.client-name-cell {
  width: 30% !important;
}

.client-operations-cell {
  width: 70px !important;
}

mat-dialog-container.mat-dialog-container {
  overflow: hidden;
}
.mat-dialog-container.ng-trigger-dialogContainer {
  position: relative;
  border-radius: 15px;
  background: #fbfdfe;
  display: flex;
  & > .ng-star-inserted {
    width: 100%;
  }
  .dialog-footer {
    position: absolute;
    bottom: 10px;
    left: 0;
    width: 100%;
    text-align: center;
  }
}

mat-dialog-actions.mat-dialog-actions {
  justify-content: center;
  padding: 0;
}

.mat-dialog-content.full-height {
  max-height: 100%;
}

.cdk-global-overlay-wrapper {
  background: rgba(113, 160, 215, 0.82);
}

.modal-list .mat-list-base .mat-list-item {
  display: flex;
  height: auto;
  min-height: 40px;
  border-bottom: 1px solid $gray-light;
  padding-top: 5px;
  padding-bottom: 5px;
}

.mat-list-base a.mat-list-item {
  color: $gray;
  height: 40px;
}

.font-blue {
  color: $blue-dark;
}

.crop-text {
  max-width: 88px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@media screen and (max-width: 500px) {
  .crop-text {
    max-width: 55px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.ngx-datatable .datatable-body .datatable-scroll {
  display: inline-block;
  width: 100% !important;
}

@media (min-width: 768px) {
  body.cat__menu-left--visible .cat__content {
    margin-left: 19.95rem;
  }
}

.cdk-overlay-container {
  z-index: 1050;
}

.swal2-actions .swal2-styled.swal2-confirm {
  margin: 20px auto;
  border-radius: 30px;
  width: 20rem;
  font-weight: bold;
}

.btn.btn-link {
  &:hover, &:focus {
    color: inherit !important;
  }
}

.swal2-container .swal2-popup {
  border-radius: 15px;
}

@media screen and (max-width: 768px) {
  .receipt-datatable .ngx-datatable .datatable-header {
    display: none;
  }
}

.add-client-button {
  padding: 0;
  margin: 0;
  border: 0;
  background: none;
  outline: 0 !important;
  &:hover, &:focus {
    outline: 0 !important;
  }
}

.bottom-bar {
  position: fixed;
  background: #fff;
  bottom: 0;
  width: 100%;
  justify-content: center;
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.modal-body.user-modal {
  overflow: auto;
  width: calc(100% + 40px);
  margin: 30px 0 30px -20px;
  padding: 0 20px;
}

.company-preview {
  position: absolute;
  right: 30px;
  top: -55px;
  mat-icon {
    position: absolute;
    bottom: 0;
    right: -30px;
  }
}