/* THEMES: GREEN */
body.cat__theme--green .cat__top-bar {
    background: #528e74;
    border-bottom-color: #528e74;
}

body.cat__theme--green .cat__top-bar__mini-chart {
    color: #fff;
}

body.cat__theme--green .cat__top-bar__mini-chart__inner i {
    background: #fff;
}

body.cat__theme--green .cat__top-bar__search {
    border-bottom-color: rgba(255, 255, 255, 0.2);
}

body.cat__theme--green .cat__top-bar__search input {
    background: none;
}

body.cat__theme--green .cat__top-bar__item > .dropdown > a {
    color: #fff;
}

body.cat__theme--green .cat__top-bar__item > .dropdown > a:hover {
    color: #e4e9f0;
}

body.cat__theme--green .cat__top-bar__menu-button {
    color: #fff;
}

body.cat__theme--green .cat__top-bar__menu-button:hover {
    opacity: .7;
}

body.cat__theme--green .cat__menu-left__logo {
    background: #528e74;
}

body.cat__theme--green .cat__menu-left__lock {
    background: #528e74;
}

body.cat__theme--green .cat__menu-left__pin-button {
    opacity: .7;
}

body.cat__theme--green .cat__menu-left__pin-button:hover {
    opacity: 1;
}

body.cat__theme--green .cat__menu-left__pin-button div {
    background: #fff;
}

body.cat__theme--green .cat__menu-left__pin-button div:before, body.cat__theme--green .cat__menu-left__pin-button div:after {
    background: #fff;
}

body.cat__theme--green .cat__menu-left {
    background: #fff;
    border-right: 1px solid #e4e9f0;
}

body.cat__theme--green .cat__menu-left__item > a {
    color: #74708d;
}

body.cat__theme--green .cat__menu-left__item--active > a,
body.cat__theme--green .cat__menu-left__item:hover > a,
body.cat__theme--green .cat__menu-left__item:active > a,
body.cat__theme--green .cat__menu-left__item:focus > a {
    background: #528e74;
    color: #ffffff;
}

body.cat__theme--green .cat__menu-left__item--active > a:before,
body.cat__theme--green .cat__menu-left__item:hover > a:before,
body.cat__theme--green .cat__menu-left__item:active > a:before,
body.cat__theme--green .cat__menu-left__item:focus > a:before {
    display: none !important;
}

body.cat__theme--green .cat__menu-left__submenu--toggled.cat__menu-left__item {
    background: #528e74;
    color: #fff;
}

body.cat__theme--green .cat__menu-left__submenu--toggled.cat__menu-left__item > a {
    color: #ffffff;
}

body.cat__theme--green .cat__menu-left__submenu--toggled.cat__menu-left__item > a .cat__menu-left__icon {
    color: #ffffff;
}

body.cat__theme--green .cat__menu-left__submenu > .cat__menu-left__list {
    background: #f4f6f9;
}

body.cat__theme--green .cat__menu-left__icon {
    color: #c7c5d1;
}

body.cat__theme--green .cat__menu-left__item--active > a > .cat__menu-left__icon,
body.cat__theme--green .cat__menu-left__item:hover > a > .cat__menu-left__icon,
body.cat__theme--green .cat__menu-left__item:active > a > .cat__menu-left__icon,
body.cat__theme--green .cat__menu-left__item:focus > a > .cat__menu-left__icon {
    color: #fff;
}

body.cat__theme--green .cat__menu-left__submenu.cat__menu-left__item > a:after {
    border-color: transparent #c7c5d1 transparent transparent;
}

body.cat__theme--green .cat__menu-left__divider {
    background: #e4e9f0;
}

body.cat__theme--green .cat__menu-left .jspScrollable .jspDrag:after {
    background: #e2e1e8 !important;
}

@media (min-width: 768px) {
    body.cat__theme--green.cat__config--vertical.cat__config--compact .cat__menu-left__item > a:before {
        background: #e4e9f0;
    }
}

body.cat__theme--green.cat__config--horizontal .cat__menu-left {
    border-right: none;
    border-bottom: 1px solid #e4e9f0;
}

@media (min-width: 768px) {
    body.cat__theme--green.cat__config--horizontal .cat__menu-left__list--root > li.cat__menu-left__item > a {
        border-left-color: #e4e9f0;
    }
    body.cat__theme--green.cat__config--horizontal .cat__menu-left__list--root > li.cat__menu-left__item:hover > a {
        border-left-color: #528e74;
    }
    body.cat__theme--green.cat__config--horizontal .cat__menu-left__list--root > li.cat__menu-left__item:hover > a .cat__menu-left__icon {
        color: #fff !important;
    }
    body.cat__theme--green.cat__config--horizontal .cat__menu-left__list--root > li.cat__menu-left__item:hover > a {
        background: #528e74;
        color: #ffffff;
    }
    body.cat__theme--green.cat__config--horizontal .cat__menu-left__list--root > li.cat__menu-left__item:hover > a > .cat__menu-left__icon {
        color: #8f8ca4;
    }
}
