/*  BOOTSTRAP SELECT */
.bootstrap-select .btn-default {
    color: #74708d !important;
    background-color: #ffffff;
    border-color: #e4e9f0;
}

.bootstrap-select .btn-default:hover {
    border-color: #b8beca;
    background: #ffffff !important;
}

.bootstrap-select .btn-default:focus, .bootstrap-select .btn-default:active {
    border-color: #0088ff !important;
    background: #ffffff !important;
    color: #74708d !important;
}

.bootstrap-select.show .btn-default {
    border-color: #0088ff !important;
    background: #ffffff !important;
    color: #74708d !important;
}

.bootstrap-select .dropdown-menu li a {
    padding: 0.35rem 1.07rem;
    outline: none;
    display: block;
}

.bootstrap-select .dropdown-menu li a:hover {
    color: #0088ff;
}

.bootstrap-select .dropdown-menu li a.opt {
    padding-left: 1.07rem !important;
}

.bootstrap-select .dropdown-menu li.hidden {
    display: none;
}

.bootstrap-select .dropdown-menu li.no-results {
    margin-left: 0.57rem;
    margin-right: 0.57rem;
    padding: 0.57rem 0.71rem;
    -webkit-border-radius: 5px;
            border-radius: 5px;
    background: #f2f4f8;
    color: #74708d;
}

.bootstrap-select .filter-option {
    text-overflow: ellipsis;
}

.bootstrap-select .dropdown-toggle:focus {
    outline: none !important;
}
