/*  CHARTIST TOOLTIP PLUGIN */
.chartist-tooltip {
    position: absolute;
    display: none;
    min-width: 5em;
    padding: 8px 10px;
    background: #393749;
    color: #fff;
    text-align: center;
    pointer-events: none;
    z-index: 100;
    -webkit-transition: opacity .2s linear;
    transition: opacity .2s linear;
    -webkit-border-radius: 3px;
            border-radius: 3px;
    -webkit-transform: translate3d(10px, 40px, 0);
            transform: translate3d(10px, 40px, 0);
}

.chartist-tooltip.tooltip-show {
    display: inline-block;
}

.chartist-tooltip:before {
    position: absolute;
    bottom: -14px;
    left: 50%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    pointer-events: none;
    border-color: transparent;
    border-top-color: #393749;
    border-width: 7px;
    margin-left: -8px;
}

.chartist-tooltip.hide {
    display: block;
    opacity: 0;
    visibility: hidden;
}
