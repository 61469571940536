/* THEMES: ORANGE */
body.cat__theme--orange .cat__top-bar {
    background: #fdeedd;
}

body.cat__theme--orange .cat__top-bar__search input {
    background: none;
}

body.cat__theme--orange .cat__menu-left__logo {
    background: #f39834;
}

body.cat__theme--orange .cat__menu-left__lock {
    background: #f39834;
}

body.cat__theme--orange .cat__menu-left__pin-button {
    opacity: .7;
}

body.cat__theme--orange .cat__menu-left__pin-button:hover {
    opacity: 1;
}

body.cat__theme--orange .cat__menu-left__pin-button div {
    background: #fff;
}

body.cat__theme--orange .cat__menu-left__pin-button div:before, body.cat__theme--orange .cat__menu-left__pin-button div:after {
    background: #fff;
}

body.cat__theme--orange .cat__menu-left {
    background: #fff;
    border-right: 1px solid #e4e9f0;
}

body.cat__theme--orange .cat__menu-left__item > a {
    color: #74708d;
}

body.cat__theme--orange .cat__menu-left__item--active > a {
    background: #f39834 !important;
    color: #ffffff !important;
}

body.cat__theme--orange .cat__menu-left__item--active > a:before {
    display: none !important;
}

body.cat__theme--orange .cat__menu-left__item--active > a > .cat__menu-left__icon {
    color: #fff !important;
}

body.cat__theme--orange .cat__menu-left__item:hover > a,
body.cat__theme--orange .cat__menu-left__item:active > a,
body.cat__theme--orange .cat__menu-left__item:focus > a {
    background: #fdeedd;
}

body.cat__theme--orange .cat__menu-left__item:hover > a:before,
body.cat__theme--orange .cat__menu-left__item:active > a:before,
body.cat__theme--orange .cat__menu-left__item:focus > a:before {
    display: none !important;
}

body.cat__theme--orange .cat__menu-left__submenu--toggled.cat__menu-left__item {
    background: #f39834 !important;
    color: #fff !important;
}

body.cat__theme--orange .cat__menu-left__submenu--toggled.cat__menu-left__item > a {
    color: #ffffff !important;
    background: #f39834 !important;
}

body.cat__theme--orange .cat__menu-left__submenu--toggled.cat__menu-left__item > a .cat__menu-left__icon {
    color: #ffffff !important;
}

body.cat__theme--orange .cat__menu-left__submenu > .cat__menu-left__list {
    background: #f4f6f9;
}

body.cat__theme--orange .cat__menu-left__icon {
    color: #d4d3dc;
}

body.cat__theme--orange .cat__menu-left__item--active > a > .cat__menu-left__icon {
    color: #fff;
}

body.cat__theme--orange .cat__menu-left__item:hover > a > .cat__menu-left__icon,
body.cat__theme--orange .cat__menu-left__item:active > a > .cat__menu-left__icon,
body.cat__theme--orange .cat__menu-left__item:focus > a > .cat__menu-left__icon {
    color: #c7c5d1;
}

body.cat__theme--orange .cat__menu-left__submenu.cat__menu-left__item > a:after {
    border-color: transparent #d4d3dc transparent transparent;
}

body.cat__theme--orange .cat__menu-left__divider {
    background: #e4e9f0;
}

body.cat__theme--orange .cat__menu-left .jspScrollable .jspDrag:after {
    background: #e2e1e8 !important;
}

@media (min-width: 768px) {
    body.cat__theme--orange.cat__config--vertical.cat__config--compact .cat__menu-left__item > a:before {
        background: #e4e9f0;
    }
}

body.cat__theme--orange.cat__config--horizontal .cat__menu-left {
    border-right: none;
    border-bottom: 1px solid #e4e9f0;
}

@media (min-width: 768px) {
    body.cat__theme--orange.cat__config--horizontal .cat__menu-left__list--root > li.cat__menu-left__item > a {
        border-left-color: #eef1f5;
    }
    body.cat__theme--orange.cat__config--horizontal .cat__menu-left__list--root > li.cat__menu-left__item:hover > a {
        border-left-color: #f39834;
    }
    body.cat__theme--orange.cat__config--horizontal .cat__menu-left__list--root > li.cat__menu-left__item:hover > a .cat__menu-left__icon {
        color: #fff !important;
    }
    body.cat__theme--orange.cat__config--horizontal .cat__menu-left__list--root > li.cat__menu-left__item:hover > a {
        background: #f39834;
        color: #ffffff;
    }
    body.cat__theme--orange.cat__config--horizontal .cat__menu-left__list--root > li.cat__menu-left__item:hover > a > .cat__menu-left__icon {
        color: #8f8ca4;
    }
}
