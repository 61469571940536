/*  EDITABLE TABLE */
.editable-table td {
    outline: none !important;
}

.editable-table + input, .editable-table input:focus {
    border: 1px solid #0190fe !important;
    outline: 0 !important;
    outline-offset: 0 !important;
    -webkit-box-sizing: content-box;
       -moz-box-sizing: content-box;
            box-sizing: content-box;
}

.editable-table.table-inverse + input, .editable-table.table-inverse input:focus {
    border-color: #d2d9e5 !important;
    border-bottom: none !important;
}
