/*  NESTABLE */
.dd {
    position: relative;
    display: block;
    margin: 0;
    padding: 0;
    list-style: none;
}

.dd button {
    outline: none;
}

.dd-list {
    display: block;
    position: relative;
    margin: 0;
    padding: 0;
    list-style: none;
}

.dd-list .dd-list {
    padding-left: 2.14rem;
}

.dd-collapsed .dd-list {
    display: none;
}

.dd-item,
.dd-empty,
.dd-placeholder {
    display: block;
    position: relative;
    margin: 0;
    padding: 0;
    min-height: 2.14rem;
    line-height: 2.14rem;
}

.dd-handle {
    display: block;
    height: 2.85rem;
    margin: 0.35rem 0;
    padding: 0.35rem 0.71rem;
    text-decoration: none;
    border: 1px solid #e4e9f0;
    background: #fff;
    -webkit-border-radius: 3px;
            border-radius: 3px;
    cursor: move;
}

.dd-handle:hover {
    color: #fff;
    background: #0190fe;
    border-color: #0190fe;
}

.dd-item > button {
    display: block;
    position: relative;
    cursor: pointer;
    float: left;
    width: 2.14rem;
    height: 2.14rem;
    margin: 0.35rem 0;
    padding: 0;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    border: 0;
    background: transparent;
    line-height: 2.35rem;
    text-align: center;
    font-family: FontAwesome;
    font-size: 0.78rem;
    color: #74708d;
}

.dd-item > button:before {
    content: "\f067";
    display: block;
    position: absolute;
    width: 100%;
    text-align: center;
    text-indent: 0;
}

.dd-item > button[data-action="collapse"]:before {
    content: "\f068";
}

.dd-placeholder, .dd-empty {
    margin: 0.35rem 0;
    padding: 0;
    min-height: 2.14rem;
    background: #f2f4f8;
    border: 1px dashed #e4e9f0;
    -webkit-border-radius: 3px;
            border-radius: 3px;
}

.dd-empty {
    border: 1px dashed #e4e9f0;
    min-height: 7.14rem;
    background-color: #ffffff;
}

.dd-dragel {
    position: absolute;
    pointer-events: none;
    z-index: 9999;
    opacity: .8;
    list-style: none;
}

.dd-dragel li {
    list-style: none;
}

.dd-dragel > .dd-item .dd-handle {
    margin-top: 0;
}

.dd-dragel .dd-item > button {
    display: none;
}

.dd-dragel .dd-handle {
    line-height: 1.42rem;
    min-height: 2.14rem;
    padding: 0.35rem 0.71rem;
}

.dd3-content {
    display: block;
    height: 2.85rem;
    margin: 0.35rem 0;
    padding: 0.35rem 0.71rem 0.35rem 2.85rem;
    text-decoration: none;
    border: 1px solid #e4e9f0;
    background: #ffffff;
    -webkit-border-radius: 3px;
            border-radius: 3px;
}

.dd-dragel > .dd3-item > .dd3-content {
    margin: 0;
}

.dd3-item > button {
    margin-left: 30px;
}

.dd3-item > button.hover {
    color: #222034;
}

.dd3-handle {
    position: absolute;
    margin: 0;
    left: 0;
    top: 0;
    cursor: move;
    width: 2.14rem;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    border: 1px solid #e4e9f0;
    background: #f2f4f8;
    -webkit-border-top-right-radius: 0;
            border-top-right-radius: 0;
    -webkit-border-bottom-right-radius: 0;
            border-bottom-right-radius: 0;
    color: #74708d;
}

.dd3-handle:before {
    font-family: FontAwesome;
    content: "\f0c9";
    display: block;
    position: absolute;
    left: 0;
    top: -1px;
    width: 100%;
    text-align: center;
    text-indent: 0;
    font-size: 1rem;
    line-height: 2.85rem;
}

.dd3-handle:hover {
    background: #0190fe;
}

.dd3-handle:hover + .dd3-content {
    border-color: #0190fe;
}
