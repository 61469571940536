/*  NPROGRESS */
#nprogress .bar {
    height: 3px;
    background: #0190fe;
}

#nprogress .spinner {
    width: 10rem;
    padding: 0.57rem 1.07rem;
    background: #ffffff;
    -webkit-border-radius: 4px;
            border-radius: 4px;
    border: 1px solid #e4e9f0;
    right: auto;
    left: 50%;
    top: 0.92rem;
    margin-left: -5.22rem;
}

#nprogress .spinner:after {
    content: "Loading...";
    display: inline-block;
    position: absolute;
    top: 0.5rem;
    left: 3.42rem;
}

#nprogress .spinner-icon {
    border-top-color: #0190fe;
    border-left-color: #0190fe;
}

body.menu-top #nprogress .bar {
    height: 4px;
}
